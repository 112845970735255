import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Divider, Loader, Grid } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import PhoneInput from '../../components/ui/PhoneInput';

import { __ } from '../../i18n';
import parse from '../../lib/parse';
import { sendGoogleAnalyticsEvent } from '../../lib/analytics';

const errorMap = {
  email: __(
    'Email address is not valid: check domain name and if it contains @ and .',
  ),
  phone: __('Phone number is not valid: check area code and number length'),
};

const switchLoginTypeMap = {
  phone: (
    <div>
      {__('or login using the email')}{' '}
      <i className={'icon fa regular fa envelope'} />
    </div>
  ),
  email: (
    <div>
      {__('or login using the phone')}{' '}
      <i className={'icon fa regular fa mobile'} />
    </div>
  ),
};
@inject('store', 'client')
@observer
export default class Prospect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      phoneFormatted: '',
      type: 'email',
      error: false,
      loading: 'page',
      disabled: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  switchType = () => {
    const { store } = this.props;
    const type = this.state.type === 'phone' ? 'email' : 'phone';

    this.setState({ type, [type]: '', error: false, disabled: true });

    sendGoogleAnalyticsEvent(
      {
        name: '(Auth) Switch',
        category: `Switch to LOGIN WITH ${type.toUpperCase()}`,
      },
      { store },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.validate();
  };

  validate = () => {
    const { type } = this.state;
    const valid =
      type === 'email'
        ? this.state.email && parse.validateEmail(this.state.email)
        : this.state.phone && parse.validatePhone('', this.state.phone);

    if (!valid) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false }, () => {
        this.submit();
      });
    }
  };

  submit = async () => {
    const { store, client } = this.props;
    const { type } = this.state;

    const params = {
      [type]:
        type === 'email'
          ? this.state.email
          : this.state.phone.replace(/[^0-9]+/g, ''),
    };

    this.setState({ loading: 'request' });

    try {
      const data = await client.mutate({
        mutation: gql`
          mutation WalleGetAuthenticationMethod(
            $email: String
            $phone: String
          ) {
            getAuthenticationMethod(input: { email: $email, phone: $phone }) {
              authenticationMethod
              isNewUser
            }
          }
        `,
        variables: params,
        options: {
          fetchPolicy: 'network-only',
        },
      });

      const { isNewUser } = data.data.getAuthenticationMethod;

      let url;

      store.auth = {
        awaitingSendCode: true,
        type,
      };

      if (isNewUser) {
        store.auth.address = params.email || params.phone;
        store.auth.addressAssociatedToCode = params.email || params.phone;
        url = 'auth/code';
      } else {
        store.auth.user = {
          email: params.email,
          phone: params.phone,
          authenticationMethod:
            data.data.getAuthenticationMethod.authenticationMethod,
          methodType: 'login',
        };
        store.auth.addressAssociatedToCode = params.email || params.phone;
        store.auth[type] = params[type];
        url = 'auth/login';
      }

      this.setState({ loading: false });

      this.props.router.push(url + this.props.location.search);
    } catch (err) {
      this.setState({ loading: false });

      if (err.networkError) {
        this.props.store.snackbar = {
          active: true,
          message: __('No connection available'),
          dismissAfter: 4000,
        };
      } else {
        store.auth.prospectType = type;
        store.auth.phoneFormatted = this.state.phoneFormatted;
        store.auth.contact =
          type === 'email'
            ? this.state.email
            : this.state.phone.replace(/[^0-9]+/g, '');

        this.props.router.push('auth/inboundingSelector');
      }
    }
  };

  render() {
    const { type, error, loading, disabled } = this.state;
    if (loading === 'page') {
      return (
        <div id="AuthProspect" style={{}}>
          {/* <h1 className="label title">{__('Welcome to ClassApp')}</h1> */}
          <Divider hidden />
          <Loader active inline />
          {this.props.children}
        </div>
      );
    }

    return (
      <div id="AuthProspect">
        <h1 className="label" style={{ fontSize: '28px', textAlign: 'left' }}>
          {__('Welcome to ClassApp!')}
        </h1>
        <Divider hidden />
        <p
          className="label subtitle"
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            margin: '12px 0px',
            textAlign: 'left',
          }}
        >
          {__('To sign up or login, provide the information below:')}
        </p>
        <Form>
          <p
            className="label"
            style={{ textAlign: 'left', padding: '5px 0px', margin: '0px' }}
          >
            {type === 'phone' ? __('Phone') : 'E-mail'}
          </p>

          <div style={{ marginBottom: '36px' }}>
            {type === 'phone' ? (
              <PhoneInput
                name="address"
                newStyleEnabled
                placeholder={__('Area Code + Phone')}
                red={error}
                error={error && errorMap.phone}
                inputStyle={{
                  borderRadius: '0px 8px 8px 0px',
                  borderLeft: 'none',
                  fontSize: '16px',
                }}
                onKeyPress={(e) => e.key === 'Enter' && this.handleSubmit(e)}
                onChange={(value) =>
                  this.setState({
                    phoneFormatted: value,
                    phone: value,
                    disabled: value === undefined,
                  })
                }
              />
            ) : (
              <Input
                name="email"
                placeholder={__('exemplo@mail.com')}
                red={error}
                onKeyPress={(e) => e.key === 'Enter' && this.handleSubmit(e)}
                error={error && errorMap.email}
                inputStyle={{ borderRadius: '8px', fontSize: '16px' }}
                onChange={({ target: { value } }) =>
                  this.setState({ email: value, disabled: value === '' })
                }
              />
            )}
          </div>

          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Button
                  primary
                  full
                  round
                  text={__('Continue')}
                  style={{ padding: '16px 24px' }}
                  onClick={this.handleSubmit}
                  loading={!!loading}
                  disabled={disabled || !!loading}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <span
                  id="switchLoginType"
                  onClick={() => this.switchType()}
                  style={{
                    color: '#0069FF',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                >
                  {switchLoginTypeMap[type]}
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {this.props.children}
      </div>
    );
  }
}
